<template>
  <div>
    <van-nav-bar
      fixed
      placeholder
      title="搜索"
      left-arrow
      @click-left="$router.back()"
    />
    <!-- 顶部背景 -->
    <div
      class="bg-height"
      :style="{ background: $store.getters['common/color'].primary }"
    ></div>
    <!-- 搜索组件 -->
    <div class="search-index-box relative">
      <search-index
        :type="1"
        class="search-index-main"
        ref="searchForm"
        :position="false"
        @search-number="searchNumber"
      />
    </div>
    <!-- 门票列表(大于1张票，显示) -->
    <div class="detail-view" v-if="showTicketList">
      <div class="row-view" v-for="item in ticketData" :key="item.id">
        <div class="mr-2x">
          <div class="bold font-l">{{ item.productName }}</div>
          <div class="mt-2x gray" v-if="item.useDate">
            使用日期： {{ item.useDate | date("yyyy-mm-dd") }}
          </div>
          <div class="mt-2x gray">票号： {{ item.auxiliaryId }}</div>
        </div>
        <div class="align-end flex-shrink" @click="detailBtn(item.auxiliaryId)">
          <span class="light-blue mr-1x">详情</span>
          <span class="icon icon-Return- light-blue"></span>
        </div>
      </div>
    </div>
    <!-- 门票详情 且只有一个票号时显示 -->
    <ticket-detail
      :result-detail="resultDetail"
      @search-detail="searchDetail"
      v-else-if="showTicketDetail"
    ></ticket-detail>
    <!-- 订单详情 -->
    <order-detail
      :detail-data="detailData"
      v-else-if="showOrderDetail"
      @search-detail="searchDetail"
    ></order-detail>
    <!-- 会员详情 -->
    <member-detail
      :member-detail="memberDetail"
      v-else-if="showMemberDetail"
    ></member-detail>
    <van-empty v-else-if="empty" image="search" description="暂无数据" />
  </div>
</template>

<script>
import { orderMobilemanagerProductdetails } from "../../api/search";
import { searchReportOrderDetails } from "../../api/order";
import { evidenceOneMemberInfo } from "../../api/member.js"; // MS-会员接口
import SearchIndex from "./components/search-index.vue";
import OrderDetail from "../record/OrderDetail.vue";
import MemberDetail from "../record/MemberDetail.vue";
import TicketDetail from "../record/TicketDetail";
export default {
  name: "TheSearch",
  components: { SearchIndex, OrderDetail, MemberDetail, TicketDetail },
  data() {
    return {
      // 展示条件
      showTicketList: false, //是否显示门票列表
      showTicketDetail: false, //是否显示门票详情
      showOrderDetail: false, //是否显示订单详情
      showMemberDetail: false, // 是否显示会员详情
      empty: false,
      // 展示数据
      ticketData: [], //门票列表数据
      resultDetail: {}, //门票详情数据
      detailData: {}, //订单详情数据
      memberDetail: [], // 会员详情数据
    };
  },
  methods: {
    // 搜索门票详情
    searchNumber(val) {
      let api = orderMobilemanagerProductdetails;
      // 2 订单号
      if (/^\d{19}$/.test(val)) {
        api = searchReportOrderDetails;
        api({ id: val })
          .then((res) => {
            this.title = "产品详情";
            this.showTicketList = false;
            this.showTicketDetail = false;
            if (res.id !== null) {
              this.detailData = res;
              this.showOrderDetail = true;
              this.empty = false;
            } else {
              this.showOrderDetail = false;
              this.empty = true;
            }
          })
          .catch(() => {});
      }
      // 票号
      else if (/^\d{12}$/.test(val)) {
        api({
          keyword: val,
        })
          .then((res) => {
            this.showOrderDetail = false;
            // 展示门票列表
            if (res.length > 1) {
              this.ticketData = res;
              this.showTicketList = true;
              this.showTicketDetail = false;
              this.title = "搜索";
            }
            // 展示门票详情
            else if (res.length === 1) {
              this.resultDetail = res[0];
              this.showTicketList = false;
              this.showTicketDetail = true;
              this.title = "产品详情";
            } else {
              this.showOrderDetail = false;
              this.showTicketList = false;
              this.showTicketDetail = false;
              this.empty = res.length === 0;
            }
          })
          .catch(() => {});
      } else {
        // 会员
        this.title = "景区管家";
        api = evidenceOneMemberInfo;
        api({ evidence: val })
          .then((res) => {
            this.showOrderDetail = false;
            this.showTicketList = false;
            this.showTicketDetail = false;
            this.showMemberDetail = true;
            // uni.setStorageSync("memberId", res.id); // 存会员id
            this.memberDetail = res;
          })
          .catch(() => {});
      }
    },
    //订单详情- 根据票号查门票详情接口
    searchDetail(val) {
      this.$refs.searchForm.changeValue(val);
      this.searchNumber(val);
    },
    //门票列表 - 点击详情显示门票详情内容
    detailBtn(val) {
      this.$refs.searchForm.changeValue(val);
      this.searchNumber(val);
    },
  },
};
</script>

<style scoped lang="less">
@import "../record/detail";
.bg-height {
  height: 75px;
}
.search-index-box {
  .search-index-main {
    position: absolute;
    top: -68px;
    width: 100%;
  }
}
</style>
