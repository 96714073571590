<template>
  <!-- 主体 -->
  <div class="member-container">
    <div class="member-main">
      <!-- 会员头部信息 -->
      <div class="member-head flex-between">
        <div class="member-head-l">
          <img
            class="photo"
            :src="memberDetail.photo"
            mode="widthFix"
            lazy-load="false"
          />
        </div>
        <div class="member-head-r">
          <div class="flex-between">
            <div>{{ memberDetail.name }}</div>
            <div class="member-code">{{ memberDetail.mobile }}</div>
          </div>
          <div class="primary">
            余额：{{ memberDetail.buyBalance | money() }}
          </div>
          <div class="member-code">
            成长值：<span class="primary">{{ memberDetail.totalGrowth }}</span>
            /{{
              memberDetail.totalGrowth + memberDetail.needGrowth
            }}，距离下一级还差{{ memberDetail.needGrowth }}成长值
          </div>
        </div>
      </div>
      <!-- 会员凭证 -->
      <div class="title">会员凭证</div>
      <div class="member-info">
        <div>手机号：{{ memberDetail.mobile || "" }}</div>
        <div>证件号：{{ memberDetail.idNo || "" }}</div>
        <div>
          人像：
          <img
            class="portrait"
            :src="memberDetail.facePersonUrl"
            mode="widthFix"
            lazy-load="false"
          />
        </div>
      </div>
      <!-- 会员资产 -->
      <div class="title">会员资产</div>
      <div class="property-list flex-start">
        <div class="property-item" @click="asset('balance')">
          <div>
            <img
              class="property-img"
              mode="widthFix"
              src="../../assets/images/record/balance@2x.png"
            />
          </div>
          <div class="mt-1x">余额</div>
        </div>
        <div class="property-item" @click="asset('point')">
          <div>
            <img
              class="property-img"
              mode="widthFix"
              src="../../assets/images/record/point@2x.png"
            />
          </div>
          <div class="mt-1x">积分</div>
        </div>
        <div class="property-item" @click="product">
          <div>
            <img
              class="property-img"
              mode="widthFix"
              src="../../assets/images/record/order@2x.png"
            />
          </div>
          <div class="mt-1x">产品</div>
        </div>
        <div class="property-item" @click="coupon">
          <div>
            <img
              class="property-img"
              mode="widthFix"
              src="../../assets/images/record/coupon@2x.png"
            />
          </div>
          <div class="mt-1x">优惠券</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberDetail",
  props: {
    memberDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    // 跳转余额页面
    asset(type) {
      this.$router.push({
        path: "/record/MemberBalance",
        query: {
          type: type,
          mobile: this.memberDetail.mobile,
        },
      });
    },
    // 产品
    product() {
      this.$router.push({
        path: "/record/MemberProduct",
        query: {
          mobile: this.memberDetail.mobile,
        },
      });
    },
    // 优惠券
    coupon() {
      this.$router.push({
        path: "/record/MemberCoupon",
        query: {
          memberId: this.memberDetail.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.member-container {
  padding: 10px;
  .member-main {
    background: #ffffff;
    border-radius: 9px;
    .member-head {
      padding: 13px;
      .member-head-l {
        width: 65px;
        .photo {
          width: 50px;
          height: 50px;
          border-radius: 25px;
        }
      }
      .member-head-r {
        width: calc(100% - 65px);
        line-height: 20px;
        .member-code {
          font-size: 12px;
          color: #8a8a8a;
        }
      }
    }
    .title {
      font-weight: bold;
      padding: 10px 10px 0px;
      border-top: 1px solid #f1f1f1;
    }
    .member-info {
      padding: 0px 10px 10px;
      color: #8a8a8a;
      font-size: 12px;
      line-height: 27px;
      .portrait {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        vertical-align: top;
      }
    }
    .property-list {
      padding: 10px;
      .property-item {
        width: 25%;
        text-align: center;
        font-size: 12px;
        color: #8a8a8a;
        .property-img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
